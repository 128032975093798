<template>
  <div>
    <c-card title="대기-자가측정 결과 업로드" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-3">
          <c-plant :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
        </div>
        <div class="col-3">
          <c-datepicker
            :editable="false"
            label="측정년도"
            type="year"
            default="today"
            name="measureYear"
            v-model="data.measureYear"
          />
        </div>
        <div class="col-3">
          <c-select
            type="edit"
            :editable="false"
            codeGroupCd="AIR_INSPECT_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            label="측정구분"
            name="airInspectTypeCd"
            v-model="data.airInspectTypeCd">
          </c-select>
        </div>
        <div class="col-3">
          <c-text
            :editable="false"
            label="측정명"
            name="envAirSelfMeasureMstName"
            v-model="data.envAirSelfMeasureMstName">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'self-measure-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          envAirSelfMeasureMstId: '',
        }
      },
    },
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envAirSelfMeasureMstId: '',  // 대기 자가측정 일련번호
        measureYear: '',  // 대기 자가측정 년도
        airInspectTypeCd: null,  // 대기 검사항목 구분
        envAirSelfMeasureMstName: '',  // 대기 자가측정명
      },
      headerUrl: '',
      excelUploadInfo: {
        rowKeys: ['envAirMstOutletName'], // 데이터들의 키 속성값
        taskClassCd: 'ENV_AIR_SELFMEASURE', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '배출구',
            align: 'center',
            type: 'text',
            style: 'width: 80px',
            sortable: false,
          },
          {
            required: true,
            name: 'measureDt',
            field: 'measureDt',
            label: '측정일자',
            align: 'center',
            type: 'date',
            style: 'width: 140px',
            sortable: false,
          },
          {
            name: 'dynamicPressure',
            field: 'dynamicPressure',
            label: '동압',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'staticPressure',
            field: 'staticPressure',
            label: '정압',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'velocity',
            field: 'velocity',
            label: '유속(m／s)',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'gasTemp',
            field: 'gasTemp',
            type: 'number',
            label: '가스온도℃',
            align: 'center',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'waterQuantity',
            field: 'waterQuantity',
            type: 'number',
            label: '수분량％',
            align: 'center',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'gasQuantity',
            field: 'gasQuantity',
            type: 'number',
            label: '유량(S㎥/min)',
            align: 'center',
            style: 'width: 90px',
            sortable: false,
          },
        ]],
        data: [],
        deptCd: '',
        processCd: '',
        workplace: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.headerUrl = selectConfig.env.air.self.outlet.header.url;
      this.getDetail();
      this.getHeader();
    },
    getDetail() {
      if (this.popupParam.envAirSelfMeasureMstId) {
        this.$http.url = selectConfig.env.air.self.measure.get.url;
        this.$http.param = {envAirSelfMeasureMstId: this.popupParam.envAirSelfMeasureMstId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
        },);
      }
    },
    getHeader() { 
      this.$http.url = this.headerUrl + '/upload';
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.envAirMstInspectItemIds = [];
        this.$_.forEach(_result.data, item => {
          this.envAirMstInspectItemIds.push(item.envAirMstInspectItemId);
          this.excelUploadInfo.columns[0].push(
            {
              name: item.envAirMstInspectItemId,
              field: item.envAirMstInspectItemId,
              type: 'number',
              label: item.envAirMstInspectItemName,
              align: 'center',
              style: 'width: 90px',
              sortable: false,
            }
          )
        });
      },);
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        let duplcheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }
        
        let dupl = [];
        this.$_.forEach(this.excelUploadInfo.data[0], _item => {
          dupl.push(_item.envAirMstOutletName)
        })
        let setCollection = new Set(dupl);
        duplcheck = setCollection.size < dupl.length;

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '에러가 있는 데이터가 존재합니다.\n\r데이터 확인 후 적용하시길 바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
            // window.getApp.$emit('CONFIRM', {
            //   title: '확인',
            //   message: '에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?',
            //   
            //   type: 'info', // success / info / warning / error
            //   // 확인 callback 함수  
            //   confirmCallback: () => {
            //     this.$emit('closePopup', this.excelUploadInfo.data[0]);
            //   },
            //   // 취소 callback 함수
            //   cancelCallback: () => {
            //   },
            // });
          } else if (duplcheck) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '배출구를 중복으로 입력할 수 없습니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '해당 엑셀데이터를 업로드하면,\n\r기존에 저장된 자가측정데이터를 초기화 한 후에 저장됩니다.\n\r그대로 적용하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
